.datepicker-days .datepicker-switch::after,
.datepicker-months .datepicker-switch::after,
.datepicker-years .datepicker-switch::after,
.datepicker-decades .datepicker-switch::after {
    font-family: 'FontAwesome';
    font-size: 10px;
    content: "\f054";
    padding-left: 5px;
}

fa-mobile-phone {
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 50px;
    content: "\F10B";
}

.btn-link {
    font-family: 'FS Elliot Web Bold', 'FS Elliot Web Regular', 'Arial', 'FontAwesome', arial, sans-serif;
    font-weight: normal;
    font-style: normal;
    color: #0076cf;
    text-decoration: none;
}