.person {
    margin-top: 12px;
}

.dependents {
    margin-top: 30px;
    font-size: .9em;
}

.dependents > .row > .col-md-3:nth-child(4n+1) {
    clear: both;
}

.dependents > .row > .col-lg-4:nth-child(3n+1) {
    clear: both;
}