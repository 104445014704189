.paymentAmount {
    font-weight: bold;
    font-size: 2.5em;
}

.paymentDueDate {
    font-weight: bold;
    font-size: 1.3em;
}

.paymentMethod {
    margin-top: 30px;
}