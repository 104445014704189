.big-footer .spacer {
    margin-bottom: 120px;
}

.footer .info .info-row {
    padding-bottom: 20px;
}

.footer .info {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100px;

    text-align: center;
    background-color: #eeeeee;
    color: #777777;
    padding-top: 20px;
    border-width: 1px 1px 0;
}

.small-footer .info {
    height: 180px;
}

.small-footer .spacer {
    margin-bottom: 200px;
}

.small-footer .info div {
    width: 200px;
    margin-left: auto;
    margin-right: auto;
}