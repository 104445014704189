html {
    position: relative;
    min-height: 100%;
}

body {
    margin-bottom: 60px;
}

.navbar-default {
    background: #0091DA;
}

.navbar-default .navbar-nav > .active > a {
    background: #277293;
}

.control-label {
    font-size: .9em;
}

.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus,
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
    background: #5AA5C6;
}

.navbar-ndc-brand {
    display: block;
    /*background: url(../images/NDC_Logo_blue.png) no-repeat transparent;*/
    background-size: contain;
    height: 35px;
    width: 256px;
    margin-right: 15px;
    -ms-interpolation-mode: bicubic;
}

.navbar-brand {
    padding-top: 10px;
    padding-bottom: 10px;
}

.email-icon::after {
    font-family: 'FontAwesome';
    font-size: 48px;
    content: "\f0e0";
}

.login-help-link {
    font-size: 12px;
    white-space: nowrap;
    cursor: pointer;
}

.port3001 {
    margin-top: -22px;
    margin-bottom: 22px;
    display: block;
    border-top: 2px solid red;
}

a {
    font-family: 'FS Elliot Web Bold', 'FS Elliot Web Regular', 'Arial', 'FontAwesome', arial, sans-serif;
}