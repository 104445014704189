.contact, .memberInfo, .paymentHistoryLogo {
    display: none;
}

@media print {
    .print, .search-panel, .footer {
        display: none;
    }

    .contact, .memberInfo, .paymentHistoryLogo  {
        display: inline;
    }
}
