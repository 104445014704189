.bottomPad {
    margin-bottom: 5px;
}

.member-card-label {
    padding-right: 5px;
}

.logo {
    width: 80%;
}