.modal-backdrop {
    opacity: 0.5 !important;
}

.title {
    font-weight: bold;
    font-size: 1.3em;
}

.additional-information {
    font-size: .9em;
}