.btn-link, .btn-link:active, .btn-link.active, .btn-link[disabled], fieldset[disabled] .btn-link {
    background-color: transparent !important;
    box-shadow: none;
    border: 0;
}

.memberCard {
    margin: 0 auto;
    max-width: 340px;
    padding: 5px;
}

.memberCardButtons, .memberCardPrint {
    width: 100%;
    text-align: center;
}