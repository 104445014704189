.center-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5px;
}

.member-card-label {
    padding-right: 5px;
}

.logo {
    width: 100%;
}

.logo-corner {
    width: 15%;
    height: auto;
}

.flex-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}